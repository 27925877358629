:root {
  --background-color: #232323;   /* Dark background color */
  --text-color: #f2f2f2;         /* Light text color */
  --black-color: #000000;        /* Black color */
  --gray-color: #969696;         /* Medium gray tone */
  --accent-color-light: rgb(50,50,50);     /* Lighter gray, accent or secondary background */
  --white-color: #ffffff;       /* White background */
  --secondary-color: rgb(30,30,30);
}

@font-face {
  font-family: king;
  src: url('./assets/Kingthings Extortion.ttf');
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: var(--secondary-color);
}

@keyframes gradientBackground {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

/* Scrollbar */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background-color: black;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #000000, #434343, #1f1c2c);
  background-size: 400% 400%;
  animation: gradientBackground 15s ease infinite;
}